import { Image, Pressable, StyleSheet, Text, View } from "react-native-web";
import { COLORS, FONT_FAMILY, SIZE } from "../../utils/constants";
import Card from "./Card";
import Icon from "./Icon";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";


type Props = {}

const VideoThumbnail = (props: Props) => {
    const [hover, setHover] = useState(false)
    return (
        <View
            style={styles.container}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Card
                paddingHorizontal={0}
                paddingVertical={0}
                style={[styles.card, {
                    backgroundColor: hover ? '#eceff0' : 'white'
                }]}>
                <View style={styles.cardContent}>
                    <View style={styles.imageWrapper}>
                        <Image
                            style={styles.videoImage}
                            source={require('../../assets/v1.png')}
                            resizeMode='cover'
                            resizeMethod="resize"

                        />
                        <Pressable
                            style={{
                                backgroundColor: hover ? '#0003' : 'transparent',
                                ...styles.playIconWrapper,
                            }}>
                            <Icon
                                style={{
                                    color: 'white',
                                    width: hover ? 25 : 5,
                                    height: hover ? 25 : 5,
                                    opacity: hover ? 1 : 0,
                                    transitionDuration: '0.4s',
                                    transitionProperty: 'all',
                                }}
                                icon={faPlayCircle}
                            />
                        </Pressable>
                        <Text
                            style={[
                                styles.durationText,
                                {
                                    opacity: hover ? 1 : 0.6,
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                }
                            ]}>
                            3:50
                        </Text>
                    </View>
                    <View style={styles.cardFooter}>
                        <Text style={styles.videoTitle}>Video title goes here and there</Text>
                        <Text style={styles.textCategory}>videoCategory</Text>
                        <Text style={styles.info}>1.8M views icons 11Months ago</Text>
                    </View>
                </View>
            </Card>
        </View>
    )
}

export default VideoThumbnail


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
        // width: '100%',
        minWidth: 270,
        marginTop: 2,
    },
    playIconWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '0.4s',
        transitionProperty: 'backgroundColor'
    },
    playIcon: {

    },
    cardContent: {
        padding: 8,
        flexDirection: 'row',
    },
    card: {
        alignItems: 'flex-start',
        // width: 270,
        // width: '100%'
    },
    cardFooter: {
        // padding: 12,
        paddingLeft: 12,
        cursor: 'pointer'
    },
    durationText: {
        color: '#ffffff',
        backgroundColor: COLORS.danger,
        fontFamily: FONT_FAMILY,
        fontSize: SIZE.sm,
        fontWeight: '500',
        paddingHorizontal: 6,
        position: 'absolute',
        bottom: 8,
        right: 8,
        transitionDuration: '0.4s',
        transitionProperty: 'backgroundColor'
    },
    title: {
        color: COLORS.title,
        fontSize: 16,
        fontWeight: '500',
        fontFamily: FONT_FAMILY,
        userSelect: "none"
    },
    videoImage: {
        width: 95,
        height: 60,
    },
    imageWrapper: {
        position: 'relative',
    },
    info: {
        marginTop: 2,
        color: COLORS.grayAC,
        fontWeight: '500',
        fontSize: SIZE.xsm,
        textAlign: 'left',
        fontFamily: FONT_FAMILY,
        userSelect: "none"
    },
    videoTitle: {
        color: COLORS.black,
        fontWeight: '600',
        fontSize: SIZE.normal,
        marginBottom: 5,
        fontFamily: FONT_FAMILY,
        textAlign: 'left',
        userSelect: "none"

    },
    textCategory: {
        color: COLORS.suceess,
        fontSize: SIZE.sm,
        textAlign: 'left',
        fontFamily: FONT_FAMILY,
        userSelect: "none"
    }

})