import { Col, Row } from 'react-bootstrap'
import { Route, Routes } from 'react-router-dom'
import { Login, Signup } from '../../components'
//swiper carousel
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "./styles.css";

const AuthLayout = () => {

    return (
        <div>
            <Row>
                <Col md={5} className="auth-left flex-all-center" style={{ maxHeight: '100vh' }}>
                    <Routes>
                        <Route key="login" path="/*" element={<Login />} />
                        <Route key="signup" path="signup" element={<Signup />} />
                    </Routes>
                </Col>
                <Col md={7} className='bg-theme' style={{ height: '100vh' }}>
                    <div className="swiper-div">
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            pagination={{ clickable: true }}
                            slidesPerView={1}
                            autoplay={{
                                delay: 2000,
                                pauseOnMouseEnter: true,
                                disableOnInteraction: false
                            }}
                            loop
                            className='swiper-container'
                        >
                            <SwiperSlide>
                                <div className='slide'>
                                    <img

                                        src={require('../../assets/login.png')}
                                        alt="First slide"
                                    />
                                    <h5 className="mt-5 mb-3 text-center">Watch videos offline</h5>
                                    <p className="mb-4 text-center">
                                        when an unknown printer took a galley of type and scrambled<br />
                                        it to make a type specimen book. It has survived not <br />
                                        only five centuries
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='slide'>
                                    <img

                                        src={require('../../assets/login.png')}
                                        alt="First slide"
                                    />
                                    <h5 className="mt-5 mb-3 text-center">Download videos effortlessly</h5>
                                    <p className="mb-4 text-center">
                                        when an unknown printer took a galley of type and scrambled<br />
                                        it to make a type specimen book. It has survived not <br />
                                        only five centuries
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='slide'>
                                    <img

                                        src={require('../../assets/login.png')}
                                        alt="First slide"
                                    />
                                    <h5 className="mt-5 mb-3 text-center">Create GIFs</h5>
                                    <p className="mb-4 text-center">
                                        when an unknown printer took a galley of type and scrambled<br />
                                        it to make a type specimen book. It has survived not <br />
                                        only five centuries
                                    </p>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                </Col >
            </Row >
        </div >
    )
}

export default AuthLayout