import { useContext, useState } from 'react'
import {
    Col,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Row
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { IAuth } from '../../Interfaces'
import { setToken } from '../../utils/storageService'
import { UserContext } from '../../utils/UserContext'
import { Button } from '../shared'
import './styles.css'

type Props = {}

const Signup = (props: Props) => {
    const { setUserState } = useContext(UserContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [promoCode, setPromoCode] = useState('');


    const handleSubmit = () => {
        //check form validation
        if (username.length === 0 || password.length === 0) {
            console.log('no empty fields');
        } else {
            // this is dummy login logic, replace once backend is ready
            const dummyUser: IAuth = {
                token: 'dummyToken',
                user: {
                    email: 'dummyEmail@ggmmil.com',
                    firstName: 'test firstName',
                    lastName: 'test lastName',
                    phone: username,
                    id: 'test id',
                    photo: 'https://picsum.photos/id/237/200/300',
                    roleId: 'user'
                }
            }
            if (dummyUser.user)
                setUserState(dummyUser.user)
            //save user in localStorage
            setToken(dummyUser)
            navigate('/', { replace: true });
        }


    }

    return (
        <div className='flex-col login-container'>
            <div className='flex-all-center'>
                <img
                    className='img-fluid'
                    src={require('../../assets/favicon.png')}
                    alt='logo'
                />
            </div>
            <h5 className='mt-3 mb-3 text-center' >Welcome to Video</h5>
            <p className='text-center'>
                It is a long established fact that a reader
                <br />
                will be distracted by the readable.
            </p>
            <Form>
                <FormGroup controlId='mobileNumber' className='mt-2'>
                    <FormLabel>Mobile number</FormLabel>
                    <FormControl
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                        placeholder="Enter mobile number"
                    />
                </FormGroup>
                <FormGroup controlId='password' className='mt-3'>
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                    />
                </FormGroup>
                <FormGroup controlId='promocode' className='mt-3'>
                    <FormLabel>Promocode</FormLabel>
                    <FormControl
                        value={promoCode}
                        onChange={(e) => setPromoCode(e.target.value)}
                        type="Promocode"
                        placeholder="Promocode"
                    />
                </FormGroup>
                <div className="mt-4">
                    <Row >
                        <Col xs={12}>
                            <Button
                                onClick={handleSubmit}
                                htmlType='button'
                                title='Sign up'
                            />
                        </Col>
                    </Row>
                </div>
            </Form>
            <div className="text-center mt-5">
                <p className="light-gray">Don’t have an account?
                    <Link to={'/'}>&nbsp;Sign In</Link>
                </p>
            </div>
        </div>
    )
}

export default Signup