import React, { useState } from 'react'
import { Button as Btn } from 'react-bootstrap';
import { Text } from 'react-native-web';
import { FONT_FAMILY } from '../../utils/constants';


type Props = {
    onClick: () => void;
    styles?: React.CSSProperties;
    count?: string;
    team: 'Red' | 'Blue';

}

const TeamButton = (props: Props) => {
    const { onClick, styles, count, team, } = props
    const red = team === 'Red';
    const blue = team === 'Blue';
    const [hover, setHover] = useState(false);
    return (
        <Btn
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
            type='button'
            className={red && hover ? 'bg-danger'
                : blue && hover ? 'bg-info' : ''}
            style={{
                width: '100%',
                borderColor: hover ? 'transparent' : red ? '#ff516b' : '#17a2b8',
                backgroundColor: 'transparent',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 2,
                color: hover ? '#fff' : blue ? '#17a2b8' : '#ff516b',
                fontSize: 12,
                fontWeight: '400',
                whiteSpace: 'nowrap',
                maxHeight: 27,
                ...styles,
            }}
        >
            <Text style={{
                color: hover ? '#fff' : blue ? '#17a2b8' : '#ff516b',
                fontSize: 12,
                fontWeight: '400',
                whiteSpace: 'nowrap',
                fontFamily: FONT_FAMILY,

            }}>{red ? 'Red' : 'Blue'}{' '}<Text style={{ fontWeight: '700', marginLeft: 5 }}>{count}</Text></Text>

        </Btn>
    )
}

export default TeamButton
