import { ProgressBar } from 'react-bootstrap'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native-web'
import { COLORS } from '../../utils/constants';

type Props = {
    progress: number;
    style?: StyleProp<ViewStyle>
}

const Progress = (props: Props) => {
    const { progress, style: customStyles } = props;
    return (
        <View style={[styles.container, customStyles]}>
            <ProgressBar
                variant='danger'
                now={progress}
                max={5000}
                min={0}
                style={styles.progress}
                label={`${progress} Tokens`}
            />
        </View>
    )
}

export default Progress


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        marginTop: 12
    },
    progress: {
        width: '90%',
        color: COLORS.primary,
        backgroundColor: COLORS.fogWhite,
        borderRadius: 2,
    }



});