import { Image, StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY, SIZE } from '../../utils/constants'

type Props = {}

const FooterMini = (props: Props) => {
    return (
        <View style={styles.container}>
            <View>
                <Text style={styles.copyrightText}>© Copyright 2022 <Text style={{ fontWeight: '700', color: COLORS.textDark }}>Beladed Inc</Text>.. All Rights Reserved</Text>
                <Text style={styles.xmText}>Please read out <Text style={{ color: COLORS.danger }}>Community Guidlines</Text></Text>
            </View>
            <View style={styles.right}>
                <Image source={require('../../assets/google.png')} style={styles.downloadImage} />
                <Image source={require('../../assets/apple.png')} style={[styles.downloadImage, { marginLeft: 2 }]} />
            </View>
        </View>
    )
}

export default FooterMini


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.fogWhite,
        paddingHorizontal: 16,
        paddingVertical: 16,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 24,
    },
    copyrightText: {
        fontFamily: FONT_FAMILY,
        color: COLORS.gray8,
        fontSize: SIZE.normal,
    },
    xmText: {
        color: COLORS.gray8,
        fontFamily: FONT_FAMILY,
        fontSize: SIZE.xsm
    },
    right: {
        flexDirection: 'row',
    },
    downloadImage: {
        width: 95,
        height: 32,
        resizeMode: 'contain',
        backgroundColor: '#231f20',
        borderRadius: 3
    },

})