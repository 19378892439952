import { Image, StyleSheet, Text, View } from "react-native-web";
import { COLORS, CONSTYLES, FONT_FAMILY, SIZE } from "../../utils/constants";
import Card from "./Card";
import videoThumbnail from '../../assets/v1.png'
import SubscribeButton from "./SubscribeButton";


type Props = {
    isSubScribed?: boolean;
    count?: string;
    name?: string;
}

const PopularChannelItem = (props: Props) => {
    const { isSubScribed = false, count = '', name = '' } = props;
    return (
        <View style={styles.container}>
            <Card paddingHorizontal={12} paddingVertical={24} style={styles.card}>
                <View style={styles.cardContentWrapper}>
                    <Image
                        style={styles.videoImage}
                        source={{ uri: videoThumbnail }}
                        resizeMode='cover'
                        resizeMethod="resize"
                    />
                    <View style={styles.cardFooter}>
                        <SubscribeButton
                            onClick={() => { }}
                            isSubscribed={isSubScribed}
                            count={count}
                            styles={{ marginTop: 3, marginBottom: 7 }}
                        />
                        <Text style={styles.videoTitle}>{name}</Text>
                        <Text style={styles.info}>382,323 subscribers</Text>
                    </View>
                </View>
            </Card>
        </View>
    )
}

export default PopularChannelItem


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        flex: 1,
        alignItems: 'flex-start',
        width: 270,
    },
    cardFooter: {
        padding: 12,
    },
    durationText: {
        color: '#ffffff',
        backgroundColor: COLORS.danger,
        fontSize: SIZE.sm,
        opacity: CONSTYLES.opacity,
        fontWeight: '500',
        paddingHorizontal: 6,
        position: 'absolute',
        bottom: 8,
        right: 8,
        fontFamily: FONT_FAMILY

    },
    title: {
        color: COLORS.title,
        fontSize: 16,
        fontWeight: '500',
        fontFamily: FONT_FAMILY

    },
    videoImage: {
        width: 80,
        height: 80,
        borderRadius: 40,
    },
    cardContentWrapper: {
        position: 'relative',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    info: {
        marginTop: 2,
        color: COLORS.grayAC,
        fontWeight: '500',
        fontSize: SIZE.xsm,
        fontFamily: FONT_FAMILY

    },
    videoTitle: {
        color: COLORS.black,
        fontWeight: '600',
        fontSize: SIZE.normal,
        marginBottom: 5,
        fontFamily: FONT_FAMILY

    },

})