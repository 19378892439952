import { faArrowRight, faEnvelope, faGlobeAmericas, faMobile } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { Image, StyleSheet, Text, TextInput, View } from "react-native-web"
import { COLORS, FONT_FAMILY, FOOTER_LINKS, SIZE } from "../../utils/constants"
import { Icon } from "."


type Props = {}

const Footer = (props: Props) => {
  const [emailFocus, setEmailFocus] = useState(false);
  return (
    <View style={styles.container}>
      <View style={styles.siteInfo}>
        <Image source={require('../../assets/logo.png')} style={styles.logoImage} />
        <View style={styles.siteSubInfo}>
          <Text style={styles.textAddress}>86 Petersham town, New South wales Waedll Steet, Australia</Text>
          <View style={styles.contactWrapper}>
            <Icon icon={faMobile} style={styles.contactIcon} />
            <Text style={styles.contactText}>+61 525 240 310</Text>
          </View><View style={styles.contactWrapper}>
            <Icon icon={faEnvelope} style={styles.contactIcon} />
            <Text style={styles.contactText}>iamosahan@gmail.com</Text>
          </View><View style={styles.contactWrapper}>
            <Icon icon={faGlobeAmericas} style={styles.contactIcon} />
            <Text style={styles.contactText}>www.askbootstrap.com</Text>
          </View>
        </View>
      </View>
      <View style={styles.siteLinksContainer}>
        {
          FOOTER_LINKS.map((footerItem, index) => (
            <View style={styles.siteLinksColumn} key={`title${index}${footerItem.title}`}>
              <Text selectable={false} style={styles.siteLinkTitle}>{footerItem.title}</Text>
              {
                footerItem.subLinks.map((item, index) => (
                  <Text
                    onClick={() => {/*navigate(item.link)*/ }}
                    key={`subItem${index}${item.title}${item.link}`}
                    style={styles.link}
                    selectable={false}
                  >
                    {item.title}
                  </Text>
                ))
              }
            </View>
          ))
        }
      </View>
      <View style={styles.footerRight}>
        <Text style={styles.siteLinkTitle}>NEWSLETTER</Text>
        <View style={styles.inputWrapper}>
          <TextInput
            onFocus={() => setEmailFocus(true)}
            onBlur={() => setEmailFocus(false)}
            placeholder="Email Address"
            style={[styles.input, { backgroundColor: emailFocus ? 'white' : COLORS.inputBg }]}
          />
          <Icon className="bg-theme" icon={faArrowRight} style={styles.inputIcon} />
        </View>
        <Text style={styles.xsmText}>It is a long established fact that a reader will be distracted by..</Text>
        <View style={styles.downloadAppWrapper}>
          <Text style={[styles.siteLinkTitle, { marginBottom: 6 }]}>DOWNLOAD APP</Text>
          <View style={styles.downloadAppImages}>
            <Image source={require('../../assets/google.png')} style={styles.downloadImage} />
            <Image source={require('../../assets/apple.png')} style={[styles.downloadImage, { marginLeft: 2 }]} />
          </View>
        </View>
      </View>
    </View >
  )
}

export default Footer

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    paddingTop: 35,
    paddingBottom: 20,
    backgroundColor: COLORS.fogWhite,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 22,
    marginTop: 24,

  },
  siteInfo: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: 190,

  },
  siteSubInfo: {
    marginTop: 24,
    flex: 1
  },
  logoImage: {
    width: 150,
    height: 23,
  },
  textAddress: {
    fontFamily: FONT_FAMILY,
    fontsiz: SIZE.normal,
    color: COLORS.gray8,
    marginBottom: '1rem'
  },
  contactWrapper: {
    felx: 1,
    flexDirection: 'row',
    marginTop: 7
  },
  contactText: {
    fontSize: 13,
    color: COLORS.textDark,
    fontFamily: FONT_FAMILY

  },
  contactIcon: {
    color: COLORS.textDark,
    marginRight: 6,
    width: 16,
  },
  info: {
    marginTop: 2,
    color: COLORS.grayAC,
    fontWeight: '500',
    fontSize: SIZE.xsm,
    textAlign: 'left'
  },
  videoTitle: {
    color: COLORS.black,
    fontWeight: '600',
    fontSize: SIZE.normal,
    marginBottom: 5,
    textAlign: 'left'
  },
  textCategory: {
    color: COLORS.suceess,
    fontSize: SIZE.sm,
    textAlign: 'left'
  },
  siteLinksContainer: {
    flex: 3,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 12,
    minWidth: 130,
  },
  siteLinksColumn: {
    flex: 1,
    justifyContent: 'flex-start',
    minWidth: 120,
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  siteLinkTitle: {
    fontSize: SIZE.title,
    fontFamily: FONT_FAMILY,
    color: COLORS.title,
    marginBottom: 24,
  },
  link: {
    fontSize: SIZE.normal,
    fontFamily: FONT_FAMILY,
    color: COLORS.subTitle,
    lineHeight: 24,
    cursor: 'pointer',
  },
  footerRight: {
    flex: 1.2,
    paddingRight: 12,
  },
  inputWrapper: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 34,
    alignItems: 'center',
  },
  input: {
    borderColor: COLORS.inputBg,
    fontFamily: FONT_FAMILY,
    color: COLORS.inputColor,
    fontSize: SIZE.normal,
    borderRadius: 2,
    lineHeight: 1.5,
    borderWidth: 1,
    cursor: 'text',
    paddingVertical: 6,
    paddingHorizontal: 12,
    textAlign: 'left',
    textAlignVertical: 'center',
    maxWidth: 160,
    // outlineColor: 'transparent',
    // outline: 'none'
    outlineWidth: 0
  },
  inputIcon: {
    color: 'white',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    border: 1.25,
    width: 14,
    height: 16
  },
  xsmText: {
    fontFamily: FONT_FAMILY,
    fontSize: SIZE.xsm,
    color: COLORS.gray8,
    marginTop: 6,
    marginBottom: 12,
    maxWidth: 165
  },
  downloadAppWrapper: {
    marginTop: 16
  },
  downloadAppImages: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadImage: {
    width: 95,
    height: 32,
    resizeMode: 'contain',
    backgroundColor: '#231f20',
    borderRadius: 3
  },

})