import { StyleSheet, Text, View } from "react-native-web"
import { COLORS, FONT_FAMILY } from "../utils/constants"
import { Pagination, PopularModelItem } from "./shared"
import SectionHeader from "./shared/SectionHeader"

const fakeDate = [
    { count: '2.2M', isSubscribed: false, name: 'Model Name' },
    { count: '2M', isSubscribed: false, name: 'Model Name' },
    { count: '1.2M', isSubscribed: true, name: 'Model Name' },
    { count: '4.2M', isSubscribed: false, name: 'Model Name' },
    { count: '6.2M', isSubscribed: true, name: 'Model Name' },
    { count: '2.2M', isSubscribed: false, name: 'Model Name' },
    { count: '2M', isSubscribed: false, name: 'Model Name' },
    { count: '1.2M', isSubscribed: true, name: 'Model Name' },
    { count: '4.2M', isSubscribed: false, name: 'Model Name' },
    { count: '6.2M', isSubscribed: true, name: 'Model Name' },
    { count: '2.2M', isSubscribed: false, name: 'Model Name' },
    { count: '2M', isSubscribed: false, name: 'Model Name' },
    { count: '1.2M', isSubscribed: true, name: 'Model Name' },
    { count: '4.2M', isSubscribed: false, name: 'Model Name' },
    { count: '6.2M', isSubscribed: true, name: 'Model Name' },
]

type Props = {}

const PopularModels = (props: Props) => {
    return (
        <View style={styles.container}>
            <SectionHeader title="Models" />
            <View style={styles.channelList}>
                {
                    fakeDate.map((item, index) => (
                        <PopularModelItem
                            key={index} //key should not be index, should be modelId
                            count={item.count}
                            isSubScribed={item.isSubscribed}
                            name={item.name}
                        />
                    ))
                }
            </View>
            <Pagination />
        </View >
    )
}

export default PopularModels

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        zIndex: 88,
        flexDirection: 'column',
        marginLeft: 16,
    },
    channelList: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    paginationWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 24,
        marginBottom: 24,
    },
    prevText: {
        fontSize: 13,
        fontFamily: FONT_FAMILY,
        color: COLORS.gray8,
        marginRight: 4,
        cursor: 'pointer'


    },
    pageNumActive: {
        color: 'white',
        paddingVertical: 4,
        paddingHorizontal: 8,
        fontSize: 14,
        fontFamily: FONT_FAMILY,
        backgroundColor: COLORS.primary,
        cursor: 'pointer'
    },
    pageNum: {
        color: 'black',
        paddingVertical: 4,
        paddingHorizontal: 8,
        fontSize: 14,
        fontFamily: FONT_FAMILY,
        cursor: 'pointer'

    },
    nextText: {
        fontSize: 13,
        fontFamily: FONT_FAMILY,
        color: 'black',
        marginLeft: 4,
        cursor: 'pointer'
    }

});