import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"

const Icon = (props: FontAwesomeIconProps) => {
    const { style, ...rest } = props

    const baseStyle: React.CSSProperties = {
        cursor: "pointer",
        width: '1rem',
        height: '1rem',
        display: "inline-block",
    }

    return (
        <FontAwesomeIcon
            // size={"2x"} //if 1.5 rem doesnt work apply this
            fontWeight={900}
            style={{
                ...baseStyle,
                ...style,
            }}
            {...rest}
        />
    )
}

export default Icon;