import { Dropdown } from 'react-bootstrap'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native-web'
import { COLORS, FONT_FAMILY, SIZE } from '../../utils/constants'
import CustomMenuDropdown from './CustomMenuDropdown'
import CustomToggleDropdown from './CustomToggleDropdown'

type Props = {
    title?: string;
    style?: StyleProp<ViewStyle>

}

const SectionHeader = (props: Props) => {
    const { title = 'Title', style: customStyles } = props;
    return (
        <View style={[styles.headerWrapper, customStyles]}>
            <Text style={styles.title}>{title}</Text>
            <View style={{ zIndex: 99 }}>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-44">
                        <div style={{ ...styles.textSortBy, cursor: 'pointer' }} >Sort by</div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenuDropdown} style={{
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: '#eff2f3',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        textAlign: 'left',
                        borderRadius: '2px',
                        fontSize: '12px',
                        cursor: 'pointer',
                        fontFamily: FONT_FAMILY,
                    }}>
                        <Dropdown.Item ><div >Top Rated</div></Dropdown.Item>

                        <Dropdown.Item ><div >Viewed</div></Dropdown.Item>

                        <Dropdown.Item ><div>Close</div></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </View>
        </View>
    )
}

export default SectionHeader


const styles = StyleSheet.create({
    channelList: {
        flex: 1,
        flexDirection: 'row',
    },
    title: {
        color: COLORS.title,
        fontSize: SIZE.title,
        fontWeight: '500',
        fontFamily: FONT_FAMILY

    },
    textSortBy: {
        color: COLORS.primary,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY
    },
    headerWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 90,
    },

});