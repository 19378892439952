import React from 'react'
import { View } from 'react-native-web'

type Props = {
    marginTop?: string | number;
    marginBottom?: string | number;
    marginVertical?: string | number;
    paddingVertical?: string | number;
}

const HorizentalLine = (props: Props) => {
    const { marginVertical, paddingVertical, marginTop, marginBottom } = props
    return (
        <View style={{
            marginVertical,
            marginTop,
            marginBottom,
            paddingVertical,
            flex: 1,
            width: '100%',
            borderTopWidth: 1,
            borderTopColor: '#0001',
            overflow: 'visible',
            height: 0,
        }} />
    )
}

export default HorizentalLine