import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native-web';
import { NavLink } from 'react-router-dom';
import Icon from './Icon';

type Props = {
    to: string;
    icon: IconDefinition;
    text: string;
}

const DropdownNavLink = (props: Props) => {
    const { icon, text, to } = props
    const [isCollapsed, setIsCollapsed] = useState(false)
    return (
        <>
            <div
                onClick={() => { setIsCollapsed(!isCollapsed) }}
                key={text + to}
                className='menu-item'
                style={styles.menuItem}
            >
                <Icon style={styles.icon} icon={icon} />
                <span style={styles.menuTitle}>{text}</span>
                <Icon
                    style={{
                        ...styles.chevron,
                        transform: isCollapsed ? 'rotate(90deg)' : 'rotate(0deg)'
                    }}
                    icon={faChevronRight}
                />
            </div>
            <View
                style={{
                    height: isCollapsed ? '100%' : 0,
                    opacity: isCollapsed ? 1 : 0,
                    transitionDelay: '0.2s',
                    transitionDuration: '0.4s',
                    transitionProperty: 'all',
                    overflow: 'hidden'
                }}>
                <NavLink
                    key={text + to}
                    className='menu-item'
                    to={to}
                    style={({ isActive }) => ({
                        ...styles.menuItem,
                        position: 'relative',
                        top: isCollapsed ? 0 : '-6px',
                        ...(isActive ? styles.activeStyle : null),
                    })}
                >
                    <Icon style={styles.icon} icon={icon} />
                    <span style={styles.menuTitle}>{text}</span>
                </NavLink>
                <NavLink
                    key={text + to}
                    className='menu-item'
                    to={to}
                    style={({ isActive }) => ({
                        ...styles.menuItem,
                        position: 'relative',
                        top: isCollapsed ? '0px' : '-10px',
                        ...(isActive ? styles.activeStyle : null),
                    })}
                >
                    <Icon style={styles.icon} icon={icon} />
                    <span style={styles.menuTitle}>{text}</span>
                </NavLink>
                <NavLink
                    key={text + to}
                    className='menu-item'
                    to={to}
                    style={({ isActive }) => ({
                        ...styles.menuItem,
                        position: 'relative',
                        top: isCollapsed ? 0 : '-6px',
                        ...(isActive ? styles.activeStyle : null),
                    })}
                >
                    <Icon style={styles.icon} icon={icon} />
                    <span style={styles.menuTitle}>{text}</span>
                </NavLink>

            </View>
        </>
    )
}

export default DropdownNavLink

const styles = StyleSheet.create({
    menu: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 70px)',
    },
    menuItem: {
        cursor: 'pointer',
        paddingTop: 12,
        paddingBottom: 12,
        color: '#fff',
        fontSize: '13px',
        fontWeight: 600,
        paddingLeft: 14,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        position: 'relative',
        // transitionDelay: '0.5s',
        transitionDuration: '0.4s',
        transitionProperty: 'all',
    },
    activeStyle: {
        backgroundColor: 'rgb(255,255,255,0.2)'
    },
    menuTitle: {
        paddingLeft: 11,
    },
    icon: {
        marginLeft: 3,
        width: 22,
        height: 22,
    },
    chevron: {
        width: 12,
        height: 12,
        // flex: 1,
        position: 'absolute',
        right: 10,
        transitionDuration: '0.4s',
        transitionProperty: 'all',
    }
})