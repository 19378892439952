import { Image, StyleSheet, Text, View } from "react-native-web";
import { COLORS, CONSTYLES, FONT_FAMILY, SIZE } from "../../utils/constants";
import Card from "./Card";
import videoThumbnail from '../../assets/v1.png'
import Icon from "./Icon";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
    name?: string;
    verified?: boolean
    views?: string | number;
    player?: string | number;
}

const GamesItem = (props: Props) => {
    //the default values are only for debug, remove in production
    const { name = 'Model Name', views = '78,853', player = '7853', verified } = props;

    return (
        <View style={styles.container}>
            <Card paddingHorizontal={12} paddingVertical={24} style={styles.card}>
                <View style={styles.cardContentWrapper}>
                    <Image
                        style={styles.videoImage}
                        source={{ uri: videoThumbnail }}
                        resizeMode='cover'
                        resizeMethod="resize"
                    />
                    <View style={styles.cardFooter}>
                        <Text style={styles.title}>{name}&nbsp;{verified ? <Icon icon={faCheckCircle} style={styles.icon} /> : ''}</Text>
                        <Text style={styles.info}>{views}&nbsp;views | {player}&nbsp;players</Text>
                    </View>
                </View>
            </Card>
        </View>
    )
}

export default GamesItem


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        justifyContent: 'center',
        minWidth: 220,
        // maxWidth: 240,
        marginBottom: 24
    },
    card: {
        flex: 1,
        alignItems: 'flex-start',
        width: '95%',
        maxWidth: 280
    },
    cardFooter: {
        paddingTop: 12,
    },
    videoImage: {
        width: 80,
        height: 80,
        borderRadius: 40,
        cursor: 'pointer'
    },
    cardContentWrapper: {
        position: 'relative',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    info: {
        marginTop: 2,
        color: COLORS.textDark,
        fontWeight: '500',
        fontSize: SIZE.sm,
        fontFamily: FONT_FAMILY,
        cursor: 'pointer'
    },
    title: {
        color: COLORS.title,
        fontWeight: '500',
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY,
        cursor: 'pointer',
    },
    icon: {
        width: 13,
        height: 13,
        color: COLORS.suceess,
    }

})