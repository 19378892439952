import React from 'react'
import { StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY } from '../../utils/constants'

type Props = {}

const Pagination = (props: Props) => {
    return (
        <View style={styles.paginationWrapper} >
            <Text style={styles.prevText}>Previous</Text>
            <Text style={styles.pageNumActive}>1</Text>
            <Text style={styles.pageNum}>2</Text>
            <Text style={styles.pageNum}>3</Text>
            <Text style={styles.nextText}>Next</Text>
        </View>
    )
}

export default Pagination


const styles = StyleSheet.create({

    paginationWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 24,
        marginBottom: 24,
    },
    prevText: {
        fontSize: 13,
        fontFamily: FONT_FAMILY,
        color: COLORS.gray8,
        marginRight: 4,
        cursor: 'pointer'
    },
    pageNumActive: {
        color: 'white',
        paddingVertical: 4,
        paddingHorizontal: 8,
        fontSize: 14,
        fontFamily: FONT_FAMILY,
        backgroundColor: COLORS.primary,
        cursor: 'pointer'
    },
    pageNum: {
        color: 'black',
        paddingVertical: 4,
        paddingHorizontal: 8,
        fontSize: 14,
        fontFamily: FONT_FAMILY,
        cursor: 'pointer'
    },
    nextText: {
        fontSize: 13,
        fontFamily: FONT_FAMILY,
        color: 'black',
        marginLeft: 4,
        cursor: 'pointer'
    }
});
