import React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native-web'
import { FooterMini, HorizentalLine, UploadVideoItem } from '../components'
import { COLORS, commonStyles, FONT_FAMILY, SIZE } from '../utils/constants'

type Props = {}

const UploadVideo = (props: Props) => {
    return (
        <View style={{ flex: 1 }}>
            <View style={styles.container}>
                <Text style={styles.headerText}>Upload Details</Text>
                <UploadVideoItem />
                <HorizentalLine />
                <Text style={styles.formTitle}>Video Title</Text>
                <TextInput style={[styles.textInput, styles.inputTitle]}
                    placeholder="Contrary to popular belief, Lorem Ipsum (2020) is not."
                    placeholderTextColor='#0004'
                />
                <Text style={styles.formTitle}>About</Text>
                <TextInput style={[styles.textInput, styles.inputAbout]}
                    placeholder="Contrary to popular belief, Lorem Ipsum (2020) is not."
                    placeholderTextColor='#0004'
                    multiline={true}
                />
                <View style={styles.selectWrapper}>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.formTitle}>Orientation</Text>
                        <select style={styles.select}>
                            <option value="grapefruit">Grapefruit</option>
                            <option value="lime">Lime</option>
                            <option selected value="stright">Straight</option>
                            <option value="mango">Mango</option>
                        </select>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.formTitle}>Privacy Settings</Text>
                        <select style={styles.select}>
                            <option selected value="public">Public</option>
                            <option value="hidden">Hidden</option>
                        </select>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.formTitle}>Monetize</Text>
                        <select style={styles.select}>
                            <option selected value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.formTitle}>License</Text>
                        <select style={styles.select}>
                            <option selected value="standard">Standard</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', }}>
                    <View style={{ flex: 1, marginRight: 12 }}>
                        <Text style={styles.formTitle}>{`Tags (13 Tags Remaining)`}</Text>
                        <TextInput style={[styles.textInput, styles.inputTitle]}
                            placeholder="Gaming, PS4"
                            placeholderTextColor='#0004'
                        />
                    </View>
                    <View style={{ flex: 0.85, marginRight: 12 }}>
                        <Text style={styles.formTitle}>{`Tags (13 Tags Remaining)`}</Text>
                        <TextInput style={[styles.textInput, styles.inputTitle]}
                            placeholder="Gaming, PS4"
                            placeholderTextColor='#0004'
                        />
                    </View>
                    <View style={{ flex: 0.65, }}>
                        <Text style={styles.formTitle}>Language</Text>
                        <select style={{ ...styles.select, marginTop: 12, marginBottom: 12 }}>
                            <option selected value="english">English</option>
                            <option value="udrdu">Urdu</option>
                            <option value="Spanish">Spanish</option>
                            <option value="hindi">Hindi</option>
                        </select>
                    </View>
                </View>
                <Text style={styles.headerText}>{`Category ( you can select upto 6 categories )`}</Text>
                <View style={styles.checkboxWrapper}>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Abaft</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Cementry</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Scissors</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Vessel</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Rescue</Text>
                    </View><View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Trouble</Text>
                    </View>
                </View>
                <View style={styles.checkboxWrapper}>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Agnoizing</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Fall</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Spray</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Shallow</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Leg</Text>
                    </View><View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Quiet</Text>
                    </View>
                </View>
                <View style={styles.checkboxWrapper}>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Stitch</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Abaft</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Pin</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Purpose</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Rescue</Text>
                    </View><View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Sentry</Text>
                    </View>
                </View>
                <View style={styles.checkboxWrapper}>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Abaft</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Cementry</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Scissors</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Vessel</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Rescue</Text>
                    </View><View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Trouble</Text>
                    </View>
                </View>
                <View style={styles.checkboxWrapper}>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Agnoizing</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Fall</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Spray</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Shallow</Text>
                    </View>
                    <View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Leg</Text>
                    </View><View style={styles.checkboxItem}>
                        <input
                            type="checkbox"
                            id="category"
                            name="category"
                            value="abaft"
                            style={styles.checkbox}
                        />
                        <Text style={styles.checkboxLabel}>Quiet</Text>
                    </View>
                </View>
                <View style={{ alignItems: 'center', justifyContent: 'center', margin: 24 }}>
                    <Text style={commonStyles.btn}>Save Changes</Text>
                </View>
                <HorizentalLine />
                <Text style={styles.violation}>
                    There are many variations of passages of Lorem Ipsum available,
                    but the majority Terms of Service and Community Guidelines.
                    Ipsum is therefore always free from repetition, injected humour, or non
                </Text>
            </View >
            <FooterMini />
        </View>
    )
}

export default UploadVideo

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        padding: 30
    },
    headerText: {
        ...commonStyles.text,
        fontWeight: '500',
        fontSize: 16
    },
    formTitle: {
        color: COLORS.gray8,
        fontSize: 12,
        fontWeight: '600',
        fontFamily: FONT_FAMILY,
        marginTop: 18
    },
    textInput: {
        outline: 'none',
        outlineColor: 'transparent',
        outlineWidth: 0,
        paddingVertical: 6,
        paddingHorizontal: 12,
        borderColor: '#dcdfdf',
        borderWidth: 0.5,
        borderRadius: 4,
        backgroundColor: '#eceff0',
        fontFamily: FONT_FAMILY,
        fontSize: SIZE.normal,
        color: '#495057',
        marginVertical: 12,

    },
    inputTitle: {

    },
    inputAbout: {
        height: 75
    },
    selectWrapper: {
        flexDirection: 'row',
        flex: 1
    },
    select: {
        flex: 1,
        maxWidth: '95%',
        backgroundColor: '#eceff0',
        color: '#495057',
        border: '1px solid #ced4da',
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        paddingRight: 12,
        outline: 'none',
        outlineColor: 'transparent',
        outlineWidth: 0,
        marginTop: 4,
    },
    checkboxWrapper: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    checkboxItem: {
        flex: 1 / 6,
        flexDirection: 'row',
        marginTop: 6,
        marginBottom: 6,
        alignItems: 'flex-end',
    },
    checkboxLabel: {
        color: COLORS.gray8,
        fontSize: 12,
        fontWeight: '600',
        fontFamily: FONT_FAMILY,
        marginLeft: 6
    },
    checkbox: {
        width: 14,
        height: 14,
    },
    violation: {
        flex: 1,
        color: COLORS.gray8,
        fontSize: 13,
        fontFamily: FONT_FAMILY,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        marginTop: 18,
        maxWidth: '85%'
    }
})

