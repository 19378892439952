import React from 'react'
import { StyleSheet, View } from 'react-native-web'
import { Outlet, Route, Routes } from 'react-router-dom'
import { FeaturedVideos, FooterMini, HorizentalLine } from '../components'
import NavSinglePage from '../components/NavSinglePage'
import SingleHeader from '../components/SingleHeader'

type Props = {}


const Single = (props: Props) => {
    return (
        <View style={styles.container}>
            <SingleHeader />
            <NavSinglePage />
            <View style={{paddingTop: 24, paddingHorizontal: 16}}>
            <Outlet/>
            </View>
            <FooterMini/>
        </View>
    )
}

export default Single

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },

})