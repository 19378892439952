import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomMenuDropdown, CustomToggleDropdown, Icon } from './shared';
import {
  Dropdown,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import {
  faBars,
  faBell,
  faCoins,
  faEnvelope,
  faPlayCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/logo.png';

type Props = {}

const Navbar = (props: Props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const navigateTo = (path: string) => {
    navigate(path);
  }

  return (
    <div
      style={styles.navbar}>
      <Icon icon={faBars} className='px-2' />
      <div onClick={() => navigateTo('./')}>
        <img src={logo} alt="logo" className='px-2' />
      </div>
      <div style={styles.searchWrapper}>
        <InputGroup>
          <FormControl
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="button-addon2"

          />
          <InputGroup.Text className='search-btn'>
            <Icon icon={faSearch} className='px-2 hover-opacity' id='button-addon2'></Icon>
          </InputGroup.Text>

        </InputGroup>
      </div>
      <div style={styles.navbarRight}>
        <Icon style={styles.icon} icon={faPlayCircle} className='px-2' />
        <p style={styles.liveText} >Beladed Live</p>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-2">
            <div style={styles.iconWrapper}>
              <Icon style={styles.icon} icon={faCoins} className='px-2' />
              <div style={styles.badge} className='bg-success'>41</div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuDropdown} style={styles.dropDown}>
            <Dropdown.Item eventKey="1">Green</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-1">
            <div style={styles.iconWrapper}>
              <Icon style={styles.icon} icon={faBell} className='px-2' />
              <div style={styles.badge} className='bg-danger'>1241</div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuDropdown} style={styles.dropDown}>
            <Dropdown.Item eventKey="1">Red</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-3">
            <div style={styles.iconWrapper}>
              <Icon style={styles.icon} icon={faEnvelope} className='px-2' />
              <div style={styles.badge} className='bg-success'>1</div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuDropdown} style={styles.dropDown}>
            <Dropdown.Item eventKey="1">Blue</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-4">
            <div style={styles.iconWrapper} className='flex-all-center'>
              <div style={styles.avatar}></div>
              <p style={styles.liveText} >Oshan</p>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenuDropdown} style={styles.dropDown}>
            <Dropdown.Item eventKey="1">Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </div>
  )
}

export default Navbar

const styles: { [key: string]: React.CSSProperties } = {
  navbar: {
    position: 'fixed',
    top: 0,
    width: '100%',
    boxShadow: '0px 0px 10px #f2f2f2',
    zIndex: 99,
    display: 'flex',
    alignItems: 'center',
    height: 70,
    backgroundColor: '#fff'
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: '#cfcfcf',
    marginInline: 4
  },
  searchWrapper: {
    flex: 0.8,
    display: 'flex',
    alignSelf: 'center',
    marginLeft: '3rem',
    marginRight: 'auto',
    flexWrap: 'nowrap',
  },
  navbarRight: {
    marginRight: '20px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  liveText: {
    marginLeft: '0px',
    marginRight: '0.8em',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  icon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  badge: {
    position: 'absolute',
    top: '-0.8rem',
    borderRadius: '3px',
    fontSize: '0.6rem',
    textAlign: 'center',
    right: '0px',
    paddingInline: '0.2rem',
    color: 'white',
    cursor: 'pointer',
  },
  iconWrapper: {
    position: 'relative',
    cursor: 'pointer',
    marginInline: '0.3rem',
  },
  dropDown: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#eff2f3',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    textAlign: 'left',
    borderRadius: '2px',
    fontSize: '12px',
  }
}


