// convert number to string with comma separator
export const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//number to string with Units (K, M, B) with 2 decimal places
export const numberWithUnits = (x: number) => {
    if (x >= 1000000000) {
        return (Math.round(x / 1000000000 * 100) / 100).toString() + "B";
    } else if (x >= 1000000) {
        return (Math.round(x / 1000000 * 100) / 100).toString() + "M";
    } else if (x >= 1000) {
        return (Math.round(x / 1000 * 100) / 100).toString() + "K";
    } else {
        return x.toString();
    }
}