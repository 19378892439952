import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native-web'

type Props = {
    children?: JSX.Element,
    style?: StyleProp<ViewStyle>
    paddingVertical?: number,
    paddingHorizontal?: number,
    marginVertical?: number,
    marginHorizontal?: number,
}

const Card = (props: Props) => {
    const {
        children,
        style,
        paddingHorizontal = 12,
        paddingVertical = 24,
        marginHorizontal = 0,
        marginVertical = 0,
    } = props;
    return (
        <View style={[
            styles.card,
            {
                paddingTop: paddingVertical,
                paddingBottom: paddingVertical,
                paddingLeft: paddingHorizontal,
                paddingRight: paddingHorizontal,
                marginTop: marginVertical,
                marginBottom: marginVertical,
                marginLeft: marginHorizontal,
                marginRight: marginHorizontal,
            },
            style,
        ]}>
            {children}
        </View>
    )
}

export default Card



const styles = StyleSheet.create({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: '0px 0px 6px #ececec',
        backgroundColor: '#fff',
        textAlign: 'center',
        borderRadius: 2,
    }
});