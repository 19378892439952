import { StyleSheet, View } from 'react-native-web'
import { FeaturedVideos, Footer, HorizentalLine, RecentActivity } from '../components'
import PopularChannels from '../components/PopularChannels'

type Props = {}

const Dashboard = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <RecentActivity style={{ marginBottom: 16 }} />
                <HorizentalLine marginVertical={16} />
                <FeaturedVideos />
                <HorizentalLine marginBottom={16} marginTop={26} />
                <PopularChannels />
            </View>
            <Footer />
        </>
    )
}

export default Dashboard

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
    },

})