import * as React from 'react';
import { useReducer } from 'react';
import { IAction, IContextProvider, IInitialState, ILoadingAction, IThemeAction, IToggleSidebar } from '../Interfaces/store';

export const StartLoading: ILoadingAction = { type: 'setIsLoading', payload: true };
export const StopLoading: ILoadingAction = { type: 'setIsLoading', payload: false };
export const ToggleTheme: IThemeAction = { type: 'theme' };
export const ToggleSideBar: IToggleSidebar = { type: 'toggleSidebar' };

export const initialState: IInitialState = {
    theme: 'dark',
    isLoading: false,
    toggleSidebar: false,
};

export const reducer = (state: IInitialState, action: IAction): IInitialState => {
    switch (action.type) {
        case 'setIsLoading':
            return {
                ...state,
                isLoading: action.payload,
            };

        case 'theme':
            return { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' };

        case 'theme':
            return { ...state, toggleSidebar: !state.toggleSidebar };

        default:
            return state;
    }
};

export const AppContext = React.createContext<{ appState: IInitialState; dispatch: React.Dispatch<IAction> }>({
    appState: initialState,
    dispatch: () => null,
});

export const AppProvider = ({ children }: IContextProvider) => {
    const [appState, dispatch] = useReducer(reducer, initialState);

    return <AppContext.Provider value={{ appState, dispatch }}>{children}</AppContext.Provider>;
};