import React from 'react'
import { StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY } from '../../utils/constants'

type Props = {}

const ChannelInfo = (props: Props) => {
    return (
        <View style={[styles.channelInfo, styles.bgCard]}>
            <Text style={styles.infoTitle}>
                Cast
            </Text>
            <Text style={styles.infoText}>
                Nathna Drake, Vicotr Sulivan, Same Drake, Elena Fisher
            </Text>

            <Text style={styles.infoTitle}>
                Category:
            </Text>
            <Text style={styles.infoText}>
                Gaming , PS4 Exclusive , Gameplay , 1080p
            </Text>

            <Text style={styles.infoTitle}>
                About :
            </Text>
            <Text style={styles.infoText}>
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                as evolved over the years, sometimes
            </Text>
            <Text style={styles.infoTitle}>
                Tags :
            </Text>
            <View style={styles.tagWrapper}>
                <Text style={styles.tagButton}>Uncahrted 5</Text>
                <Text style={styles.tagButton}>PlayStation 4</Text>
                <Text style={styles.tagButton}>Game Pass</Text>
                <Text style={styles.tagButton}>+ 6</Text>
                <Text style={styles.tagButton}>1080</Text>
            </View>
        </View>
    )
}

export default ChannelInfo

const styles = StyleSheet.create({

    bgCard: {
        backgroundColor: 'white',
        boxShadow: '0 0 11px #ececec',
        padding: 15,
        borderRadius: 2,
    },
    channelInfo: {
        marginTop: 30,
    },
    infoTitle: {
        color: COLORS.title,
        fontFamily: FONT_FAMILY,
        userSelect: 'none',
        fontSize: 15,
        marginBottom: 6,
        fontWeight: '600'

    },
    infoText: {
        color: COLORS.gray8,
        fontFamily: FONT_FAMILY,
        userSelect: 'none',
        fontSize: 13,
        marginBottom: 16,
        paddingRight: 24

    },
    tagWrapper: {
        marginBottom: 6,
        flexDirection: 'row',
    },
    tagButton: {
        fontFamily: FONT_FAMILY,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 9,
        paddingRight: 9,
        marginRight: 4,
        color: 'white',
        backgroundColor: COLORS.grayC,
        cursor: 'pointer',
        userSelect: 'none',
        marginBottom: 4
    },
})