import { faBell, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native-web'
import ReactPlayer from 'react-player'
import { ChannelInfo, Comment, FooterMini, HorizentalLine, Icon, InputComment, SubscribeButton, VideoSidebar } from '../components'
import { COLORS, FONT_FAMILY, SIZE } from '../utils/constants'

type Props = {}

const Video = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <View style={styles.body}>
                    <View>
                        <View style={{
                            position: 'relative',
                            paddingTop: '56.25%',
                        }}>
                            <ReactPlayer
                                url='https://www.youtube.com/watch?v=8LWZSGNjuF0'
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }}
                                width='100%'
                                height='100%'
                            />
                        </View>

                    </View>
                    <View style={[styles.bgCard, styles.videoTitleWrapper]}>
                        <Text style={styles.title}>Contrary to popular belief, Lorem Ipsum (2020) is not.</Text>
                        <Text style={styles.views}>2,729,347 views</Text>
                    </View>
                    <View style={[styles.bgCard, styles.channelSubWrapper]}>
                        <View style={styles.avatar} />
                        <View style={styles.cahannelName}>
                            <Text style={styles.userName}>Osahan Channel</Text>
                            <Text style={styles.time}>Published on Aug 10, 2020</Text>
                        </View>
                        <SubscribeButton
                            onClick={() => { }}
                            isSubscribed={false}
                            count={'1.4M'}
                            styles={{ maxWidth: 120, height: 30 }}
                        />
                        <Icon icon={faBell} style={styles.bellIcon} />
                    </View>
                    <ChannelInfo />
                    <View style={styles.commentsWrapper}>
                        <View style={styles.commentsNav}>
                            <Pressable style={styles.navPressed}>VIDEO COMMENTS</Pressable>
                            <Pressable style={styles.navPress} > DISQUS COMMENTS</Pressable>
                            <Pressable style={styles.navPress}>FACEBOOK Comments</Pressable>
                        </View>
                        <InputComment />
                        <HorizentalLine />
                        <Comment />
                        <HorizentalLine />
                        <Comment />
                    </View>
                </View >
                <VideoSidebar />
            </View >
            <FooterMini />
        </>
    )
}

export default Video

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    body: {
        flex: 1,
        marginTop: 30,
        marginLeft: 30,
        minWidth: 350,
    },
    title: {
        color: COLORS.title,
        fontSize: 18,
        userSelect: 'none',
        fontWeight: '500',
        fontFamily: FONT_FAMILY,
        marginBottom: 6
    },
    views: {
        fontSize: SIZE.normal,
        userSelect: 'none',
        color: COLORS.gray8,
        fontFamily: FONT_FAMILY,
    },
    bgCard: {
        backgroundColor: 'white',
        boxShadow: '0 0 11px #ececec',
        padding: 15,
        borderRadius: 2,
    },
    videoTitleWrapper: {
        marginTop: 16
    },
    channelSubWrapper: {
        marginTop: 30,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    avatar: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: COLORS.grayAC
    },
    cahannelName: {
        flex: 1,
        marginLeft: 12,
    },
    userName: {
        color: COLORS.primary,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY,
        userSelect: 'none',
        fontWeight: '700',
        paddingBottom: 2,
        cursor: 'pointer'
    },
    time: {
        color: COLORS.gray8,
        userSelect: 'none',
        fontSize: 10,
        fontFamily: FONT_FAMILY
    },
    bellIcon: {
        border: 'solid 1px #ff516b',
        color: COLORS.primary,
        marginLeft: 6,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 8,
        paddingRight: 8
    },
    commentsWrapper: {
        marginTop: 30,
        backgroundColor: 'white',
        boxShadow: '0 0 11px #ececec',
        paddingBottom: 15,
        borderRadius: 2,
    },
    commentsNav: {
        flexDirection: 'row',
        borderBottomColor: COLORS.grayAC,
        borderBottomWidth: 0.5,
        marginBottom: 12
    },
    navPressed: {
        padding: 17,
        fontSize: 13,
        color: 'black',
        fontWeight: '600',
        fontFamily: FONT_FAMILY
    },
    navPress: {
        padding: 17,

    },
});