import { Dropdown } from "react-bootstrap"
import { StyleSheet, Text, View } from "react-native-web"
import { COLORS, FONT_FAMILY, SIZE } from "../utils/constants"
import { CustomMenuDropdown, CustomToggleDropdown, FeaturedVideoItem } from "./shared"

type Props = {}

const FeaturedVideos = (props: Props) => {
    return (
        <View style={styles.container}>
            <View style={styles.headerWrapper}>
                <Text style={styles.title}>Featured Videos</Text>
                <View style={{ zIndex: 99 }}>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-44">
                            <div style={{ ...styles.textSortBy, cursor: 'pointer' }} >Sort by</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenuDropdown} style={{
                            backgroundColor: 'white',
                            borderWidth: 1,
                            borderColor: '#eff2f3',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            textAlign: 'left',
                            borderRadius: '2px',
                            fontSize: '12px',
                            cursor: 'pointer',
                        }}>
                            <Dropdown.Item ><div >Top Rated</div></Dropdown.Item>

                            <Dropdown.Item ><div >Viewed</div></Dropdown.Item>

                            <Dropdown.Item ><div>Close</div></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </View>
            </View>
            <View style={styles.videoItemsWrapper}>
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
            </View>
        </View>
    )
}

export default FeaturedVideos

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 88,
        marginLeft: 16,
    },
    title: {
        color: COLORS.title,
        fontSize: SIZE.title,
        fontWeight: '500',
        fontFamily: FONT_FAMILY
    },
    textSortBy: {
        color: COLORS.primary,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY

    },
    headerWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 90,
    },
    videoItemsWrapper: {
        flex: 1,
        flexDirection: "row",
        flexWrap: 'wrap',
        zIndex: 1,
    },
});