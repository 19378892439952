import { StyleSheet, View } from 'react-native-web'
import { GamesItem, GoalVideoItem, Pagination } from '../components'
import FooterMini from '../components/shared/FooterMini'
import SectionHeader from '../components/shared/SectionHeader'

const fakeData = [
    { name: 'Jumbo Chess', player: '8123', verified: true, views: '178,853' },
    { name: 'Clash Royale', player: '8612', verified: true, views: '8,853' },
    { name: 'Mario Cart', player: '6123', verified: false, views: '853' },
    { name: 'Sudoko', player: '823', verified: true, views: '653' },
    { name: 'CNC', player: '123', verified: false, views: '539' },
    { name: 'Razor Blade', player: '8123', verified: true, views: '8,853' },
    { name: 'DB ini', player: '91123', verified: true, views: '853' },
]

type Props = {}

const Games = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <SectionHeader title='Games' style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 12, marginRight: 12 }} />
                <View style={styles.goalContainer}>
                    {
                        fakeData.map((item, index) => (
                            <GamesItem
                                key={index}
                                name={item.name}
                                player={item.player}
                                verified={item.verified}
                                views={item.views}
                            />
                        ))
                    }
                </View>
                <Pagination />
            </View>
            <FooterMini />
        </>
    )
}

export default Games

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        paddingTop: 24
    },
    goalContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 12,
        paddingTop: 24,
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },

})