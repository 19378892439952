import { Dropdown } from "react-bootstrap"
import { StyleSheet, Text, View } from "react-native-web"
import { COLORS, FONT_FAMILY, SIZE } from "../utils/constants"
import {
    CustomMenuDropdown,
    CustomToggleDropdown,
    PopularChannelItem
} from "./shared"

type Props = {}

const PopularChannels = (props: Props) => {
    return (
        <View style={styles.container}>
            <View style={styles.headerWrapper}>
                <Text style={styles.title}>Popular Channels</Text>
                <View style={{ zIndex: 99 }}>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggleDropdown} id="dropdown-custom-components-44">
                            <div style={{ ...styles.textSortBy, cursor: 'pointer' }} >Sort by</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenuDropdown} style={{
                            backgroundColor: 'white',
                            borderWidth: 1,
                            borderColor: '#eff2f3',
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            textAlign: 'left',
                            borderRadius: '2px',
                            fontSize: '12px',
                            cursor: 'pointer',
                        }}>
                            <Dropdown.Item ><div >Top Rated</div></Dropdown.Item>

                            <Dropdown.Item ><div >Viewed</div></Dropdown.Item>

                            <Dropdown.Item ><div>Close</div></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </View>
            </View>
            <View style={styles.channelList}>
                <PopularChannelItem count="2.2M" isSubScribed={false} name={'Channels Name'} />
                <PopularChannelItem count="1.2M" isSubScribed={false} name={'Channels Name'} />
                <PopularChannelItem count="1.2K" isSubScribed={true} name={'Channels Name'} />
                <PopularChannelItem count="200" isSubScribed={false} name={'Channels Name'} />

            </View>
        </View>
    )
}

export default PopularChannels

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        zIndex: 88,
        flexDirection: 'column',
        marginLeft: 16,
    },
    channelList: {
        flex: 1,
        flexDirection: 'row',
    },
    title: {
        color: COLORS.title,
        fontSize: SIZE.title,
        fontWeight: '500',
        marginBottom: 16,
        fontFamily: FONT_FAMILY

    },
    textSortBy: {
        color: COLORS.primary,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY
    },
    headerWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 90,
    },

});