import React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY, SIZE } from '../utils/constants'
import { VideoThumbnail } from './shared'

type Props = {}

const VideoSidebar = (props: Props) => {
    return (
        <View style={styles.rightSidebar}>
            <div
                style={{
                    maxHeight: 400,
                    marginTop: 30,
                    margin: 16,
                    overflowY: 'scroll'
                }}
                className="customScrollbar">
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
            </div>
            <Text style={styles.adSenseText}>Google Adsense 336 x 280</Text>
            <View style={styles.upNextWrap}>
                <Text style={styles.upNextText}>Up Next</Text>
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
            </View>
            <Text style={styles.adSenseText}>Google Adsense 336 x 280</Text>
            <View style={styles.upNextWrap}>
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
                <VideoThumbnail />
            </View>

        </View>

    )
}

export default VideoSidebar

const styles = StyleSheet.create({

    rightSidebar: {
        flex: 0.45,
        minWidth: 220
    },
    adSenseText: {
        color: COLORS.gray8,
        backgroundColor: '#eceff0',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 40,
        userSelect: 'none',
        textAlign: 'center',
        margin: 12,
    },
    upNextWrap: {
        margin: 12

    },
    upNextText: {
        marginBottom: 12,
        color: COLORS.title,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY,
        fontWeight: '600'
    }
});