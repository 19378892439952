import React from 'react'
import { Dimensions, StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY, SIZE } from '../utils/constants'

type Props = {}

const SingleHeader = (props: Props) => {
    return (
        <View style={styles.bg}>
            <View style={styles.flexBottom}>
                <View style={styles.avatar} />
                <View style={styles.stats}>
                    <Text style={styles.title}>Today's Stats</Text>
                    <Text style={styles.btn}>Team: <Text style={{ color: '#2677f3' }}>Blue</Text></Text>
                    <Text style={styles.btn}>Goals: <Text style={{ color: 'red' }}>Blue</Text></Text>
                    <Text style={styles.btn}>Views: <Text style={{ color: 'red' }}>Blue</Text></Text>
                    <Text style={[styles.btn, {color: 'lime'}]}>Online </Text>
                </View>
            </View>
        </View>
    )
}

export default SingleHeader


const styles = StyleSheet.create({
    bg: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: COLORS.grayAC,
        minHeight: '30vh',
        position: 'relative',
        justifyContent: 'flex-end'
    },
    flexBottom: {
        width: '100%',
        paddingVertical: 16,
        paddingHorizontal: 32,
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    avatar: {
        width: 90,
        height: 90,
        borderRadius: 45,
        borderWidth: 6,
        borderColor: 'white'
    },
    stats: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end'
    },
    title:{
            color: COLORS.white,
            fontSize: SIZE.normal,
            fontFamily: FONT_FAMILY,
            marginRight: 5
    },
    btn:{
        backgroundColor: '#000',
        color: 'white',
        borderRadius: 2,
        fontSize: 12,
        fontWeight: '600',
        fontFamily: FONT_FAMILY,
        paddingVertical: 5,
        paddingHorizontal: 10,
        marginHorizontal:  2,       
    }

})