import React from "react";

type CustomMenuProps = {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    labeledBy?: string;
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenuDropdown = React.forwardRef(
    (props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {

        return (
            <div
                ref={ref}
                style={props.style}
                className={props.className}
                aria-labelledby={props.labeledBy}
            >
                {props.children}
            </div>
        );
    }
);

export default CustomMenuDropdown;