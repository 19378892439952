import { Dropdown } from "react-bootstrap"
import { StyleSheet, Text, View } from "react-native-web"
import { COLORS, FONT_FAMILY, SIZE } from "../utils/constants"
import { CustomMenuDropdown, CustomToggleDropdown, FeaturedVideoItem } from "./shared"
import SectionHeader from "./shared/SectionHeader"

type Props = {}

const FeaturedModels = (props: Props) => {
    return (
        <View style={styles.container}>
            <SectionHeader title="Featured Models" />
            <View style={styles.videoItemsWrapper}>
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
                <FeaturedVideoItem />
            </View>
        </View>
    )
}

export default FeaturedModels

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 88,
        marginLeft: 16,
    },
    title: {
        color: COLORS.title,
        fontSize: SIZE.title,
        fontWeight: '500',
        fontFamily: FONT_FAMILY
    },
    textSortBy: {
        color: COLORS.primary,
        fontSize: SIZE.normal,
        fontFamily: FONT_FAMILY

    },
    headerWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        zIndex: 90,
    },
    videoItemsWrapper: {
        flex: 1,
        flexDirection: "row",
        flexWrap: 'wrap',
        zIndex: 1,
    },
});