import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native-web'
import { matchPath, NavLink, useMatch } from 'react-router-dom'
import { COLORS, FONT_FAMILY, PROFILE_ROUTES } from '../utils/constants'
import { Icon, SubscribeButton } from './shared'

const navLinksProfile = [
    { title: 'About', linkTo: 'About' },
    { title: 'Wishlist', linkTo: 'Wishlist' },
    { title: 'Videos', linkTo: '' },
    { title: 'Message', linkTo: 'Message' },
    { title: 'Donate', linkTo: 'Donate' },
    { title: 'Vote Model', linkTo: 'VoteModel' },
];

const NavLinkItem = ({ title, linkTo }: { title: string, linkTo: string }) => {
    const [active, setActive] = useState(false);

    return (
        <View style={[
            { height: 50, alignItems: 'center', justifyContent: 'center', cursor: 'pointer' },
            active ? { borderBottomColor: 'red', borderBottomWidth: 1, } : {}
        ]}>
            <NavLink
                to={linkTo}
                end
                style={({ isActive }) => {
                    setActive(isActive);
                    return ({
                        marginLeft: 7,
                        marginRight: 7,
                        color: active ? COLORS.primary : '#0005',
                    })
                }}
            >
                {title}
            </NavLink>
        </View>
    )
}

type Props = {}

const NavSinglePage = (props: Props) => {

    return (
        <View style={styles.container}>
            <Text style={styles.title}>Model Profile</Text>
            <Icon icon={faCheckCircle} style={styles.checkIcon} />
            <View style={styles.navWrapper}>
                {
                    navLinksProfile.map((item, index) => (
                        <NavLinkItem key={index} title={item.title} linkTo={item.linkTo} />
                    ))
                }
            </View>
            <SubscribeButton
                onClick={() => { }}
                isSubscribed={false}
                count={'12.4M'}
                styles={{maxWidth: 120, height: 30 }}
            />
        </View>
    )
}

export default NavSinglePage


const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        boxShadow: '0 0 11px #ececec',
        paddingHorizontal: 30,
        minHeight: 50,
        alignItems: 'center',
        flexDirection: 'row',
    },
    title: {
        color: 'black',
        fontFamily: FONT_FAMILY,
        fontSize: 16,
        fontWeight: '700',
        alignSelf: 'center'
    },
    checkIcon: {
        color: COLORS.suceess,
        marginLeft: 4,
        width: 16,
        height: 16,
        alignSelf: 'center'
    },
    navWrapper: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: 32,
        // justifyContent: 'center',
        alignItems: 'center',

    },

})