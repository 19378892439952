import { StyleSheet, View } from 'react-native-web'
import { FeaturedModels, HorizentalLine, PopularModels } from '../components'
import FooterMini from '../components/shared/FooterMini'

type Props = {}

const Models = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <PopularModels />
                <HorizentalLine marginVertical={16} />
                <FeaturedModels />
            </View>
            <FooterMini />
        </>
    )
}

export default Models

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        paddingTop: 24,
    },

})