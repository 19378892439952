import { Image, Pressable, StyleSheet, Text, View } from "react-native-web";
import { COLORS, FONT_FAMILY, SIZE } from "../../utils/constants";
import Card from "./Card";
import Icon from "./Icon";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Progress from "./Progress";


type Props = {
    tokenDoanted: number;
    supporters: string;
    subs: string;
    modelName: string;
    goalReached: boolean;
}

const GoalVideoItem = (props: Props) => {
    const { goalReached, modelName, subs, supporters, tokenDoanted } = props
    const [playIconHovered, setPlayIconHovered] = useState(false)
    return (
        <View style={styles.container}>
            <Card paddingHorizontal={0} paddingVertical={0} style={styles.card}>
                <>
                    <View style={styles.imageWrapper}>
                        <Image
                            style={styles.videoImage}
                            source={require('../../assets/v1.png')}
                            resizeMode='cover'
                            resizeMethod="resize"

                        />
                        <Pressable
                            onMouseEnter={() => setPlayIconHovered(true)}
                            onMouseLeave={() => setPlayIconHovered(false)}
                            style={(state) => ({
                                backgroundColor: state.hovered ? '#0003' : 'transparent',
                                ...styles.playIconWrapper,
                            })}>
                            <Icon
                                style={{
                                    color: 'white',
                                    width: playIconHovered ? 40 : 10,
                                    height: playIconHovered ? 40 : 10,
                                    opacity: playIconHovered ? 1 : 0,
                                    transitionDuration: '0.4s',
                                    transitionProperty: 'all',
                                }}
                                icon={faPlayCircle}
                            />
                        </Pressable>
                        <Text
                            style={[
                                styles.durationText,
                                {
                                    opacity: playIconHovered ? 1 : 0.6
                                }
                            ]}>
                            3:50
                        </Text>
                    </View>
                    <Progress progress={tokenDoanted} />
                    <View style={styles.cardFooter}>
                        <Text style={styles.videoTitle}>Video title goes here and there</Text>
                        <Text style={styles.textCategory}>videoCategory</Text>
                        <Text style={styles.info}>1.8M views icons 11Months ago</Text>
                    </View>
                </>
            </Card>
        </View>
    )
}

export default GoalVideoItem


const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minWidth: 270,
        maxWidth: 320,
        marginTop: 24,
        marginRight: 24,
    },
    playIconWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '0.4s',
        transitionProperty: 'backgroundColor'
    },
    playIcon: {

    },
    card: {
        flex: 1,
        alignItems: 'flex-start',
        // width: 270,
        width: '100%'
    },
    cardFooter: {
        padding: 12,
    },
    durationText: {
        color: '#ffffff',
        backgroundColor: COLORS.danger,
        fontFamily: FONT_FAMILY,
        fontSize: SIZE.sm,
        fontWeight: '500',
        paddingHorizontal: 6,
        position: 'absolute',
        bottom: 8,
        right: 8,
        transitionDuration: '0.4s',
        transitionProperty: 'backgroundColor'
    },
    title: {
        color: COLORS.title,
        fontSize: 16,
        fontWeight: '500',
        fontFamily: FONT_FAMILY

    },
    videoImage: {
        width: '100%',
        aspectRatio: 1 * 1.8,
        height: 'auto',
    },
    imageWrapper: {
        position: 'relative',
        width: '100%'
    },
    info: {
        marginTop: 2,
        color: COLORS.grayAC,
        fontWeight: '500',
        fontSize: SIZE.xsm,
        textAlign: 'left',
        fontFamily: FONT_FAMILY

    },
    videoTitle: {
        color: COLORS.black,
        fontWeight: '600',
        fontSize: SIZE.normal,
        marginBottom: 5,
        textAlign: 'left'
    },
    textCategory: {
        color: COLORS.suceess,
        fontSize: SIZE.sm,
        textAlign: 'left',
        fontFamily: FONT_FAMILY

    }

})