import { createContext, useState } from 'react';
import { IUser } from '../Interfaces';
import { IContextProvider, IUserContext } from '../Interfaces/store';
import { getToken } from './storageService';

export const UserContext = createContext({} as IUserContext);

export const UserContextProvider = ({ children }: IContextProvider) => {
    const [userState, setUserState] = useState(getToken().user as IUser);
    return <UserContext.Provider value={{ userState, setUserState }}>{children}</UserContext.Provider>;
};