import { Image, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native-web'
import { COLORS, FONT_FAMILY, SIZE } from '../utils/constants'
import { numberWithUnits } from '../utils/helper'
import { Card } from './shared'
import SectionHeader from './shared/SectionHeader';

//swiper carousel
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {
    style?: StyleProp<ViewStyle>
}

const RecentActivity = (props: Props) => {
    const { style: customStyles } = props;
    return (
        <View style={[styles.container, customStyles]}>
            <SectionHeader title='Recent Active Models' style={{ marginBottom: 24 }} />
            <Swiper
                style={styles.swiperContainer}
                modules={[Autoplay, Navigation]}
                slidesPerView={8}
                autoplay={{
                    delay: 2000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                }}
                loop
            >

                {fakeData.map((item, index) => (
                    <SwiperSlide key={index} style={styles.slide}>
                        <Card
                            paddingHorizontal={24}
                            paddingVertical={12}
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <View style={styles.cardContents}>
                                <Image
                                    style={styles.cardAvatar}
                                    source={{ uri: item.image }}
                                    resizeMode='cover'
                                />
                                <Text style={styles.title}>{item.title}</Text>
                                <Text style={styles.cardViews}>{numberWithUnits(item.views)} views</Text>
                            </View>
                        </Card>
                    </SwiperSlide>
                ))}
            </Swiper>
        </View>
    )
}

export default RecentActivity


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginLeft: 16,
        marginTop: 16,
        marginRight: 12,
        // overflow: 'hidden'
    },
    cardContents: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    recentActivityBody: {

    },
    cardAvatar: {
        width: 60,
        height: 60,
        borderRadius: 30,
        marginBottom: 8,
        backgroundColor: COLORS.fogWhite
    },
    cardTitle: {
        // flexWrap: 'nowrap'
    },
    cardViews: {
        color: COLORS.primary,
        fontSize: 11,
        fontWeight: '500',
        cursor: 'pointer',
        fontFamily: FONT_FAMILY

    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: 120,
        fontSize: 13,
        marginTop: 5,
        marginBottom: 1,
        color: COLORS.title,
        fontWeight: '500',
        cursor: 'pointer',
        fontFamily: FONT_FAMILY
    },
    swiperContainer: {
        width: '100%',
        height: '100%',
        marginBottom: 0,
        marginTop: 0.

    },
    slide: {

    },
    recentActivityHeader: {
        color: COLORS.title,
        fontSize: SIZE.title,
        fontWeight: '500',
        marginBottom: 16,
        fontFamily: FONT_FAMILY

    },

});

const fakeData = [
    { image: 'https://picsum.photos/id/237/200/300', title: 'Comedy', views: 12323414 },
    { image: 'https://picsum.photos/id/23/200/300', title: 'Gaming', views: 1212314 },
    { image: 'https://picsum.photos/id/27/200/300', title: 'Singing', views: 1132314 },
    { image: 'https://picsum.photos/id/37/200/300', title: 'Cooking', views: 314 },
    { image: 'https://picsum.photos/id/17/200/300', title: 'Life Style Advice', views: 12314 },
    { image: 'https://picsum.photos/id/347/200/300', title: 'Technology Tutorials', views: 123314 },
    { image: 'https://picsum.photos/id/212/200/300', title: 'Model Name', views: 121314 },
    { image: 'https://picsum.photos/id/257/200/300', title: 'Traveling', views: 2314 },
    { image: 'https://picsum.photos/id/137/200/300', title: 'Education', views: 4123 },
    { image: 'https://picsum.photos/id/31/200/300', title: 'Noodles, Sauces & Instagram', views: 331214 },
    { image: 'https://picsum.photos/id/21/200/300', title: 'Name', views: 22314 },
    { image: 'https://picsum.photos/id/41/200/300', title: 'Name', views: 112314 },
]