import { StyleSheet } from "react-native-web"

export const COLORS = {
    primary: '#ff516b',
    secondary: '#ff253a',
    title: '#333',
    subTitle: '#666',
    white: '#ffffff',
    black: '#000000',
    whiteOpaque: '#fff7',
    danger: '#f5234a',
    gray8: '#888',
    grayC: '#ccc',
    grayAC: '#acacac',
    suceess: '#4eda92',
    fogWhite: '#e9ecef',
    textDark: '#171b20',
    inputBg: '#eceff0',
    inputColor: '#495057',
    inputBorder: '#dcdfdf',
    borderFocus: '#80bdff',
    info: '#17a2b8',
}

export const CONSTYLES = {
    borderRadius: 2,
    opacity: 0.7,
}

export const FONT_FAMILY = 'Open Sans, sans-serif'

export const SIZE = {
    xxsm: 9,
    xsm: 10,
    sm: 11,
    normal: 13,
    title: 16,
    padding: 12,
    margin: 16,
    marginX: 30,
}

export const ROUTES = {
    DASHBOARD: '/*',
    MODELS: 'models',
    GOALS: 'goals',
    TEAMS: 'teams',
    GAMES: 'games',
    SHOWS: 'shows',
    ANNOUNCE: 'announce',
    FAVORITES: 'favorites',
    SINGLE: 'single',
    VIDEO: 'video',
    UPLOAD: 'upload',
}

export const PROFILE_ROUTES = {
    ABOUT: 'About',
    WISHLIST: 'Wishlist',
    VIDEOS: 'Videos',
    MESSAGE: 'Message',
    DONATE: 'Donate',
    VOTE: 'VoteModel',
};

type subLinks = { title: string, link: string };

export const FOOTER_LINKS: { title: string, link: string, subLinks: subLinks[] }[] = [
    {
        title: 'Company', link: '#', subLinks: [
            { title: 'About us', link: '#' },
            { title: 'Careers', link: '#' },
            { title: 'Legal', link: '#' },
            { title: 'FAQ', link: '#' },
            { title: 'Privacy', link: '#' },
            { title: 'Terms', link: '#' },
            { title: 'Contact us', link: '#' },
        ]
    },
    {
        title: 'Features', link: '#', subLinks: [
            { title: 'Retention', link: '#' },
            { title: 'People', link: '#' },
            { title: 'Messages', link: '#' },
            { title: 'Infrastructure', link: '#' },
            { title: 'Platform', link: '#' },
            { title: 'Funnels', link: '#' },
            { title: 'Live', link: '#' },
        ]
    },
    {
        title: 'Solutions', link: '#', subLinks: [
            { title: 'Enterprise', link: '#' },
            { title: 'Financial Service', link: '#' },
            { title: 'Consumer Tech', link: '#' },
            { title: 'Entertainment', link: '#' },
            { title: 'Product', link: '#' },
            { title: 'Marketing', link: '#' },
            { title: 'Analytics', link: '#' },
        ]
    },
]


export const commonStyles = StyleSheet.create({
    text: {
        fontFamily: FONT_FAMILY,
        fontSize: SIZE.normal,
        color: COLORS.title,
    },
    bgCard: {
        backgroundColor: 'white',
        boxShadow: '0 0 11px #ececec',
        padding: 15,
        borderRadius: 2,
    },
    btn: {
        fontFamily: FONT_FAMILY,
        fontSize: 12,
        color: COLORS.primary,
        paddingVertical: 6,
        paddingHorizontal: 16,
        border: '1px solid #ff516b',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        cursor: 'pointer'
    }
})