import { useContext, useEffect, useState } from 'react'
import { Text, View } from 'react-native-web';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FeaturedVideos, Navbar, Sidebar } from '../../components';
import { COLORS, FONT_FAMILY, PROFILE_ROUTES, ROUTES } from '../../utils/constants';
import { UserContext } from '../../utils/UserContext';
import Announces from '../Announces';
import Dashboard from '../Dashboard';
import Games from '../Games';
import Goals from '../Goals';
import Models from '../Models';
import Shows from '../Shows';
import Single from '../Single';
import Teams from '../Teams';
import UploadVideo from '../UploadVideo';
import Video from '../Video';

type Props = {}



const MainLayout = (props: Props) => {
    const { userState } = useContext(UserContext);
    const [sideBarWidth, setSideBarWidth] = useState(225);
    const navigate = useNavigate();

    useEffect(() => {
        if (userState === undefined || userState === null) {
            navigate('/', { replace: true });
        }
    }, [userState])

    if (userState === undefined || userState === null) {
        return null;
    }

    return (
        <div>
            <Navbar />
            <Sidebar width={sideBarWidth} />
            <div style={{ ...styles.mainContent, marginLeft: sideBarWidth, }}>
                <Routes>
                    <Route key={ROUTES.DASHBOARD} path={ROUTES.DASHBOARD} element={<Dashboard />} />
                    <Route key={ROUTES.MODELS} path={ROUTES.MODELS} element={<Models />} />
                    <Route key={ROUTES.GOALS} path={ROUTES.GOALS} element={<Goals />} />
                    <Route key={ROUTES.TEAMS} path={ROUTES.TEAMS} element={<Teams />} />
                    <Route key={ROUTES.GAMES} path={ROUTES.GAMES} element={<Games />} />
                    <Route key={ROUTES.SHOWS} path={ROUTES.SHOWS} element={<Shows />} />
                    <Route key={ROUTES.ANNOUNCE} path={ROUTES.ANNOUNCE} element={<Announces />} />
                    <Route key={ROUTES.FAVORITES} path={ROUTES.FAVORITES} element={<Models />} />
                    <Route key={ROUTES.SINGLE} path={ROUTES.SINGLE} element={<Single />} >
                        <Route key={PROFILE_ROUTES.VIDEOS} index element={<FeaturedVideos />} />
                        <Route key={PROFILE_ROUTES.ABOUT} path={PROFILE_ROUTES.ABOUT} element={<DevRoute title={PROFILE_ROUTES.ABOUT} />} />
                        <Route key={PROFILE_ROUTES.WISHLIST} path={PROFILE_ROUTES.WISHLIST} element={<DevRoute title={PROFILE_ROUTES.WISHLIST} />} />
                        <Route key={PROFILE_ROUTES.MESSAGE} path={PROFILE_ROUTES.MESSAGE} element={<DevRoute title={PROFILE_ROUTES.MESSAGE} />} />
                        <Route key={PROFILE_ROUTES.DONATE} path={PROFILE_ROUTES.DONATE} element={<Goals />} />
                        <Route key={PROFILE_ROUTES.VOTE} path={PROFILE_ROUTES.VOTE} element={<DevRoute title={PROFILE_ROUTES.VOTE} />} />
                    </Route>
                    <Route key={ROUTES.VIDEO} path={ROUTES.VIDEO} element={<Video />} />
                    <Route key={ROUTES.UPLOAD} path={ROUTES.UPLOAD} element={<UploadVideo />} />
                </Routes>
            </div>
        </div>
    )
}

export default MainLayout

const styles: { [key: string]: React.CSSProperties } = {
    mainContent: {
        marginTop: '70px', //navbarHeight
        color: '#888',
        fontSize: '13px',
        // paddingBottom: '70px', //navbarHeight
    },
}

const DevRoute = ({ title }: { title: string }) => {
    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: '15vw' }}>
            <Text style={{ color: COLORS.primary, fontFamily: FONT_FAMILY, fontSize: 22, textAlign: 'center' }}>{title}</Text>
        </View>
    )
}