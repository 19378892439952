import React from 'react'
import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native-web'
import { COLORS, FONT_FAMILY } from '../../utils/constants'

type Props = {}

const InputComment = (props: Props) => {
    return (
        <View style={styles.commentsTab}>
            <View style={styles.writeCommentWrapper}>
                <View style={styles.avatar} />
                <View style={styles.commentRight}>
                    <TextInput
                        style={styles.inputComment}
                        placeholder='Add a public comment'
                        placeholderTextColor={COLORS.grayC}
                        multiline={true}
                        scrollEnabled={false}
                    />
                    <View style={styles.flexRowJend}>
                        <Text style={styles.commentCount}>12,725 Comments</Text>
                        <View style={styles.btnWrapper}>
                            <div className='bg-danger' style={styles.btnComment}>
                                <Pressable >
                                    CANCEL
                                </Pressable>
                            </div>
                            <div className='bg-danger' style={styles.btnComment}>
                                <Pressable >
                                    COMMENT
                                </Pressable>
                            </div>
                        </View>
                    </View>

                </View>
            </View>
        </View>
    )
}

export default InputComment


const styles = StyleSheet.create({
    avatar: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: COLORS.grayAC
    },
    commentsTab: {
        paddingLeft: 17,
        paddingRight: 17,
        paddingBottom: 17,
        paddingTop: 6
    },
    writeCommentWrapper: {
        flexDirection: 'row'
    },
    commentRight: {
        flex: 1,
        marginLeft: 16,
        marginRight: 12

    },
    inputComment: {
        backgroundColor: '#eceff0',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 6,
        outlineWidth: 0,
        color: '#495057',
        fontFamily: FONT_FAMILY

    },
    flexRowJend: {
        flexDirection: 'row',
        marginTop: 12,
        alignItems: 'center',
        justifyContent: 'space-between'

    },
    commentCount: {
        color: COLORS.gray8,
        fontFamily: FONT_FAMILY,
        fontSize: 13,
        fontWeight: '700',
    },
    btnWrapper: {
        flexDirection: 'row',
    },
    btnComment: {
        fontSize: 14,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        marginLeft: 4,
        fontFamily: FONT_FAMILY,
        color: 'white'
    },
});