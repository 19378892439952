import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthLayout from './pages/authlayout/AuthLayout';
import MainLayout from './pages/mainlayout/MainLayout';
import { UserContext } from './utils/UserContext';


function App() {
  const { userState, setUserState } = useContext(UserContext);

  return (
    <div className="App">
      <Routes>
        <Route
          key='home'
          path="*"
          element={userState?.email ? <MainLayout /> : <AuthLayout />}
        />
      </Routes>
    </div>
  );
}

export default App;
