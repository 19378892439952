import React from "react";

type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {};
};

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggleDropdown = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLDivElement>) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                if (props?.onClick)
                    props.onClick(e);
            }}
        >
            {props.children}
        </div>
    )
);

export default CustomToggleDropdown;