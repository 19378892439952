import { StyleSheet, View } from 'react-native-web'
import { FeaturedModels, FooterMini, HorizentalLine, TeamsItem } from '../components'
import SectionHeader from '../components/shared/SectionHeader'

type Props = {}

const Teams = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <SectionHeader title='Teams' />
                <View style={styles.teamsItemContainer}>
                    <TeamsItem />
                    <TeamsItem />
                    <TeamsItem />

                </View>
                <HorizentalLine marginVertical={16} />
                <FeaturedModels />
            </View>
            <FooterMini />
        </>
    )
}

export default Teams

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        paddingTop: 24,
    },
    teamsItemContainer: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
    }

})