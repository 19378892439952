import React, { useState } from 'react'
import { Button as Btn } from 'react-bootstrap';


type Props = {
    isSubscribed?: boolean;
    onClick: () => void;
    styles?: React.CSSProperties;
    count?: string;

}

const SubscribeButton = (props: Props) => {
    const { isSubscribed, onClick, styles, count } = props
    const [hover, setHover] = useState(false);
    return (
        <Btn
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
            type='button'
            className={!isSubscribed && hover ? 'bg-danger'
                : isSubscribed && hover ? 'bg-secondary' : ''}
            style={{
                width: '100%',
                borderColor: hover ? 'transparent' : isSubscribed ? '#9ba8aa' : '#ff516b',
                color: hover ? '#fff' : isSubscribed ? '#9ba8aa' : '#ff516b',
                backgroundColor: 'transparent',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 12,
                paddingRight: 12,
                fontSize: 12,
                borderRadius: 2,
                fontWeight: 400,
                ...styles,
            }}
        >
            {isSubscribed ? 'Subscribed' : 'Subscribe'}{' '}{count}
        </Btn>
    )
}

export default SubscribeButton