import { StyleSheet, View } from 'react-native-web'
import { GoalVideoItem, Pagination } from '../components'
import FooterMini from '../components/shared/FooterMini'
import SectionHeader from '../components/shared/SectionHeader'

const fakeData = [
    { tokenDoanted: 5000, supporters: '311', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 4000, supporters: '3211', subs: '5,325', modelName: 'Model Name', goalReached: false },
    { tokenDoanted: 3000, supporters: '11', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 2000, supporters: '31', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 5000, supporters: '311', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 4000, supporters: '3211', subs: '5,325', modelName: 'Model Name', goalReached: false },
    { tokenDoanted: 3000, supporters: '11', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 2000, supporters: '31', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 5000, supporters: '311', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 4000, supporters: '3211', subs: '5,325', modelName: 'Model Name', goalReached: false },
    { tokenDoanted: 3000, supporters: '11', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 2000, supporters: '31', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 5000, supporters: '311', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 4000, supporters: '3211', subs: '5,325', modelName: 'Model Name', goalReached: false },
    { tokenDoanted: 3000, supporters: '11', subs: '5,325', modelName: 'Model Name', goalReached: true },
    { tokenDoanted: 2000, supporters: '31', subs: '5,325', modelName: 'Model Name', goalReached: true },

]

type Props = {}

const Goals = (props: Props) => {
    return (
        <>
            <View style={styles.container}>
                <SectionHeader title='Goals' style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 12, marginRight: 12 }} />
                <View style={styles.goalContainer}>
                    {
                        fakeData.map((item, index) => (
                            <GoalVideoItem
                                key={index}
                                tokenDoanted={item.tokenDoanted}
                                supporters={item.supporters}
                                subs={item.subs}
                                modelName={item.modelName}
                                goalReached={item.goalReached}
                            />
                        ))
                    }
                </View>
                <Pagination />
            </View>
            <FooterMini />
        </>
    )
}

export default Goals

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 12,
        paddingTop: 24
    },
    goalContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 12,
        paddingTop: 24,
        flexWrap: 'wrap'
    },

})