import {
    faBullhorn,
    faCheckCircle,
    faChessQueen,
    faCloudUploadAlt,
    faFolder,
    faHistory,
    faHome,
    faStar,
    faTheaterMasks,
    faUserAlt,
    faUserFriends,
    faUsers,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { DropdownNavLink, Icon } from "./shared";

type Props = {
    width?: string | number;
}

const sidebarMenu = [
    { icon: faHome, text: 'Home', path: '/home' },
    { icon: faUsers, text: 'Models', path: ROUTES.MODELS },
    { icon: faCheckCircle, text: 'Goals', path: ROUTES.GOALS },
    { icon: faUserFriends, text: 'Teams', path: ROUTES.TEAMS },
    { icon: faChessQueen, text: 'Games', path: ROUTES.GAMES },
    { icon: faTheaterMasks, text: 'Shows', path: ROUTES.SHOWS },
    { icon: faBullhorn, text: 'Announce', path: ROUTES.ANNOUNCE },
    { icon: faStar, text: 'Favorites', path: ROUTES.FAVORITES },
    { icon: faUserAlt, text: 'Single', path: ROUTES.SINGLE },
    { icon: faVideo, text: 'Video Page', path: ROUTES.VIDEO },
    { icon: faCloudUploadAlt, text: 'Upload Video', path: ROUTES.UPLOAD },
];

const Sidebar = (props: Props) => {
    const { width = '225px' } = props;
    return (
        <div style={{ ...styles.sidebar, width }} className="customScrollbar bg-theme">
            <div style={styles.menu}>
                {sidebarMenu.map(({ icon, text, path }) => (
                    <NavLink
                        key={text + path}
                        className='menu-item'
                        to={path}
                        style={({ isActive }) => ({
                            ...styles.menuItem,
                            ...(isActive ? styles.activeStyle : null),
                        })}
                    >
                        <Icon style={styles.icon} icon={icon} />
                        <span style={styles.menuTitle}>{text}</span>
                    </NavLink>
                ))}
                <DropdownNavLink to={ROUTES.UPLOAD} icon={faFolder} text='Pages' />
                <NavLink
                    key={'asfafa'}
                    className='menu-item'
                    to={'history'}
                    style={({ isActive }) => ({
                        ...styles.menuItem,
                        ...(isActive ? styles.activeStyle : null),
                    })}
                >
                    <Icon style={styles.icon} icon={faHistory} />
                    <span style={styles.menuTitle}>History</span>
                </NavLink>
            </div>
        </div>
    )
}

export default Sidebar

const styles: { [key: string]: React.CSSProperties } = {
    sidebar: {
        position: 'fixed',
        left: 0,
        top: '70px',
        overflowY: 'scroll',
        zIndex: 88,
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',

    },
    menuItem: {
        cursor: 'pointer',
        paddingTop: 12,
        paddingBottom: 12,
        color: '#fff',
        fontSize: '13px',
        fontWeight: 600,
        paddingLeft: 14,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center'
        transitionDuration: '0.3s',
        transitionProperty: 'all',
    },
    activeStyle: {
        backgroundColor: 'rgb(255,255,255,0.2)'
    },
    menuTitle: {
        paddingLeft: 11,
    },
    icon: {
        marginLeft: 3,
        width: 22,
        height: 22,
    }
}