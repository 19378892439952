import React, { useState } from 'react'
import { Button as Btn } from 'react-bootstrap';


type Props = {
    title: string
    onClick: () => void
    styles?: React.CSSProperties
    htmlType?: 'button' | 'submit' | 'reset' | undefined

}

const Button = (props: Props) => {
    const { title, onClick, styles, htmlType } = props
    const [hover, setHover] = useState(false);
    return (
        <Btn
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
            type={htmlType}
            className = {hover ? 'bg-theme' : ''}
            style={{
                width: '100%',
                borderColor: hover ? 'transparent' : '#ff516b',
                color: hover ? '#fff' : '#ff516b',
                backgroundColor: 'transparent',
                ...styles
            }}
        >
            {title}
        </Btn>
    )
}

export default Button