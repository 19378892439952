import { faClose } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { StyleSheet, Text, View } from 'react-native-web'
import { COLORS, commonStyles, FONT_FAMILY } from '../../utils/constants'
import Icon from './Icon'

type Props = {}

const UploadVideoItem = (props: Props) => {
    return (
        <View style={styles.container}>
            <View style={styles.uploadThumbnail} />
            <View style={styles.uploadDetails}>
                <Text style={styles.uploadTitle}>Contrary to popular belief, Lorem Ipsum (2020) is not.</Text>
                <Text style={styles.remainDetails}>102.6 MB . 2:13 MIN Remaining</Text>
                <ProgressBar
                    // variant='danger'
                    striped={true}
                    now={70}
                    style={styles.progress}
                    animated={true}
                />
                <Text style={styles.remainDetails}>Your Video is still uploading, please keep this page open until it's done.</Text>
            </View>
            <Icon icon={faClose} style={styles.closeIcon} />
        </View>
    )
}

export default UploadVideoItem

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        marginVertical: 24,
        alignItems: 'center',
        position: 'relative',
        minWidth: 560 //delete for responsivness later

    },
    uploadThumbnail: {
        width: 160,
        height: 90,
        backgroundColor: '#8781bd',
        marginRight: 24
    },
    uploadDetails: {
        flex: 1
    },
    uploadTitle: {
        fontFamily: FONT_FAMILY,
        fontSize: 13,
        color: 'black',
        fontWeight: '600',
        lineHeight: 20,
        marginBottom: 3
    },
    remainDetails: {
        fontFamily: FONT_FAMILY,
        fontSize: 11,
        color: COLORS.gray8,
        lineHeight: 17
    },
    progress: {
        // width: '95%',
        color: COLORS.primary,
        backgroundColor: COLORS.fogWhite,
        borderRadius: 2,
        marginTop: 13,
        marginBottom: 13,
        marginRight: 24,
        height: 9
    },
    closeIcon: {
        color: 'white',
        backgroundColor: COLORS.primary,
        borderRadius: 40,
        padding: 2,
        width: 10,
        height: 10,
        position: 'absolute',
        top: 0,
        right: 14
    }

})