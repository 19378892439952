import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native-web'
import { COLORS, FONT_FAMILY } from '../../utils/constants'
import Icon from './Icon'

type Props = {}

const Comment = (props: Props) => {
    return (

        <View style={styles.userCommentWrapper}>
            <View style={styles.avatar} />
            <View style={styles.userCommentSubWrapper}>
                <View style={styles.useCommentTitle}>
                    <Text style={styles.userCommentName}>Gurdeep Osahan</Text>
                    <Text style={styles.userCommentTime}>2 months ago</Text>
                </View>
                <Text style={styles.userCommentText}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Necessitatibus dicta perspiciatis odit. Voluptas cum quidem nesciunt tempore adipisci similique,
                    unde maiores laborum officiis non quas corporis quia possimus perferendis fuga.
                </Text>
                <View style={styles.userCommentFooter}>
                    <View style={styles.ReactionIconWrapper}>
                        <Icon icon={faThumbsUp} style={styles.reactIcon} />
                        <Text style={styles.reactText}>123</Text>
                    </View>
                    <View style={styles.ReactionIconWrapper}>
                        <Icon icon={faThumbsDown} style={styles.reactIcon} />
                        <Text style={styles.reactText}>102</Text>
                    </View>
                    <View style={styles.userWraps}>
                        <View style={styles.smAvatar} />
                        <View style={styles.smAvatar} />
                        <View style={styles.smAvatar} />
                        <View style={styles.smAvatar} />
                    </View>


                </View>
            </View>

        </View>

    )
}

export default Comment

const styles = StyleSheet.create({
    avatar: {
        width: 38,
        height: 38,
        borderRadius: 19,
        backgroundColor: COLORS.grayAC
    },
    userCommentWrapper: {
        padding: 15,
        flexDirection: 'row',
        fontFamily: FONT_FAMILY,
        width: '100%',
    },
    userCommentSubWrapper: {
        flex: 1,
        marginLeft: 16,
    },
    useCommentTitle: {
        flexDirection: 'row',
        alignItems: 'center'

    },
    userCommentName: {
        color: COLORS.black,
        fontSize: 16,
        fontWeight: '600',
        fontFamily: FONT_FAMILY
    },
    userCommentTime: {

        color: '#7c7c7c',
        fontSize: 11,
        fontWeight: '700',
        fontFamily: FONT_FAMILY,
        marginLeft: 6
    },
    userCommentText: {
        color: '#7b7b7b',
        fontFamily: FONT_FAMILY,
        userSelect: 'none',
        fontSize: 13,
        marginBottom: 16,
        paddingRight: 24,
        marginTop: 8


    },
    userCommentFooter: {
        flexDirection: 'row',
        alignItems: 'center'

    },
    ReactionIconWrapper: {
        flexDirection: 'row',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 4,
        paddingBottom: 4,
        border: '1px solid #ff516b',
        marginRight: 2,
        borderRadius: 3,
        alignItems: 'center',
        justifyContent: 'center'

    },
    reactIcon: {
        color: COLORS.primary,
        width: 12,


    },
    reactText: {
        color: COLORS.primary,
        fontSize: 11,
        fontWeight: '400',
        fontFamily: FONT_FAMILY,
        marginLeft: 2
    },
    userWraps: {
        flexDirection: 'row',
        marginLeft: 24,

    },
    smAvatar: {
        flex: 1,
        minWidth: 25,
        minHeight: 25,
        borderRadius: 14,
        backgroundColor: COLORS.grayAC,
        border: '2px solid #fff',
        marginLeft: '-8px'

    },
});
